<mat-toolbar class="samtoolsTopbar">
  <mat-toolbar-row>
    <div class="samtoolsTopbar-brand"></div>
    <div class="samtoolsTopbar-actions">
      <button class="samtoolsTopbar__toggle" mat-button [matMenuTriggerFor]="menu">
        <mat-icon class="samtoolsTopbar__icon">account_circle</mat-icon>
        <span class="samtoolsTopbar__label small" *ngIf="user">{{'topBar.text.welcome' | translate}}</span>
        <span class="samtoolsTopbar__label">{{user ? user.firstname + ' ' + user.name : ''}}</span>
        <mat-icon class="samtoolsTopbar__cursor">arrow_drop_down</mat-icon>
      </button>
      <mat-menu class="samtoolsTopbar__matmenu" #menu="matMenu" [xPosition]="'after'">
        <button mat-menu-item (click)="goToMyAccount()"><mat-icon class="material-icons-outlined">account_circle</mat-icon>{{ 'topBar.text.myAccount' | translate }}</button>
        <button mat-menu-item (click)="logout()"><mat-icon class="material-icons-outlined">power_settings_new</mat-icon>{{ 'topBar.text.disconnected' | translate }}</button>
      </mat-menu>

      <div class="samtoolsTopbar__language" *ngIf="languageOptions.length > 0">
        <button class="samtoolsTopbar__toggle" mat-button [matMenuTriggerFor]="menuLanguage" *ngIf="languageSelect">
          <mat-icon class="samtoolsTopbar__icon material-icons-outlined">language</mat-icon>
          <span class="samtoolsTopbar__label">{{ languageSelect.label }}</span>
          <mat-icon class="samtoolsTopbar__cursor">arrow_drop_down</mat-icon>
        </button>
        <mat-menu class="samtoolsTopbar__matmenu" #menuLanguage="matMenu" [xPosition]="'after'">
          <button mat-menu-item *ngFor="let language of languageOptions" (click)="selectLanguage(language.id)"><mat-icon class="material-icons-outlined">language</mat-icon>{{ language.label }} </button>
        </mat-menu>
      </div>
    </div>
  </mat-toolbar-row>
</mat-toolbar>
