import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { UserServiceProvider } from '@adista/window-kit-ui';
import { Observable, ReplaySubject, Subscription, of, combineLatest, throwError, Subscriber } from 'rxjs';
import { tap, map, mergeMap, catchError } from 'rxjs/operators';

import { UserService } from '@api/api/api';
import { ApiResponse, Permission, User } from '@api/model/models';
import { OAuthService, OAuthData } from './oauth.service';
import { HttpErrorResponse } from '@angular/common/http';
import { Location } from '@angular/common';

@Injectable({
  providedIn: 'root',
})
export class CurrentUserService implements UserServiceProvider {
  private readonly _userSubject = new ReplaySubject<User>(1);
  private _user: User;
  private _userSubscription: Subscription;
  private _retrieving = false;

  get currentUser$(): Observable<User> {
    this.retrieveUser();
    return this._userSubject.asObservable();
  }

  constructor(
    private location: Location,
    private readonly userService: UserService,
    private readonly oAuthService: OAuthService,
    private readonly router: Router,
  ) {}

  public isAuthenticated(): Observable<boolean> {
    return combineLatest([of(this.oAuthService.isLogged()), this.currentUser$.pipe(map((u: User) => u !== undefined))]).pipe(
      map((result: [boolean, boolean]) => result[0] && result[1]),
    );
  }

  public redirectToLogin(keyMessage = ''): void {
    this.router.navigate(['/login'], { queryParams: { keyMessage } });
  }

  public retrieveUser(): void {
    let onForgotPasswordPage: boolean;
    this.location.onUrlChange((url) => {
      if (url === '/login/forgotPassword') {
        onForgotPasswordPage = false;
      } else {
        onForgotPasswordPage = true;
      }
    });
    if (!this.oAuthService.isLogged() && !onForgotPasswordPage) {
      this._user = null;
      this.oAuthService.removeToken();
      this.redirectToLogin();
      return;
    }

    if (this._user || this._retrieving) {
      return;
    }

    this._retrieving = true;
    this._userSubscription = this.userService.getCurrentUser().subscribe(
      (user: User) => {
        this._userSubscription.unsubscribe();
        this._userSubscription = undefined;

        this._user = user;
        this._userSubject.next(this._user);
        this._retrieving = false;
      },
      () => this.logout(),
    );
  }

  public login(credentials: any): Observable<OAuthData> {
    return this.oAuthService.checkLoginPassword(credentials).pipe(
      mergeMap(() => {
        return this.oAuthService.checkUniqueLogin(credentials);
      }),
      mergeMap(() => {
        return this.oAuthService.haveLicence(credentials.username.toLowerCase());
      }),
      mergeMap(() => {
        return this.oAuthService.fetchAccessToken(credentials);
      }),
      catchError((error) => {
        return throwError(error);
      }),
      tap(() => this.retrieveUser()),
    );
  }

  public logout(keyMessage = ''): void {
    this._retrieving = false;
    this._user = null;
    this.oAuthService.removeToken();
    this._userSubject.next(null);
    this.redirectToLogin(keyMessage);
  }

  public can(permissionSlug: string): Observable<boolean> {
    // TODO change when permissions are active
    return this.currentUser$.pipe(
      map((user: User) => {
        if (user) {
          return user.role.permissions.some(
            (userPermission: Permission) => userPermission.slug.toLowerCase() === permissionSlug.toLowerCase(),
          );
        }
        return false;
      }),
    );
  }

  public canSync(permission: string): boolean {
    // TODO change when permissions are active
    // return this._user.roles.includes(permission);
    return true;
  }
}
