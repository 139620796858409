<div class="boardnav" [ngClass]="{'boardnav--collapsed' : currentCat === 0, 'boardnav--activetouch' : activeMobileMenu}">
    <div class="boardnav__togglemenu">
        <button mat-button (click)="activeMobileMenu = !activeMobileMenu">
            <mat-icon>menu</mat-icon>
        </button>
    </div>
    <div class="boardnav__scroller">
        <div class="boardnav__close">
            <button mat-button (click)="activeMobileMenu = !activeMobileMenu">
                <mat-icon>close</mat-icon>
            </button>
        </div>
        <h2 class="boardnav__logo" [ngStyle]="{'background-image': 'url(' + urlLogo + ')'}"><span>Sam Tool</span></h2>
        <ul class="boardnav__nav" #toggleButton>
            <li *ngFor="let item of menus; let index = index;">
                <button mat-button class="boardnav__item" [ngClass]="{'boardnav__item--active' : currentCat === index}" (click)="menuItemClicked(item, index)">
                    <mat-icon class="material-icons-outlined">{{ item.icon }}</mat-icon>
                    <span class="boardnav__itemlabel">{{ item.label }}</span>
                    <mat-icon class="boardnav__expand" *ngIf="!!item.subItems && item.subItems.length > 0">expand_more</mat-icon>
                </button>
                <ul *ngIf="!!item.subItems && item.subItems.length > 0">
                    <li>
                        <button mat-button class="boardnav__subitem" *ngFor="let subItem of item.subItems" (click)="menuItemClicked(subItem, 0)">{{ subItem.label }}</button>
                    </li>
                </ul>
            </li>
        </ul>
    </div>
</div>

<div [ngClass]="{'boardtips--disabled' : closeTips}" class="boardtips">
    <div class="boardtips__wrapper">
        <button class="boardtips__close" (click)="closeTips = !closeTips" mat-mini-fab>
            <mat-icon>close</mat-icon>
        </button>
        <h2>
            <span>Pour optimisez votre expérience de navigation</span>
            <strong>Tournez votre téléphone !</strong>
            <span>(mode paysage)</span>
        </h2>
        <mat-icon class="boardtips__icon">screen_rotation</mat-icon>
    </div>
</div>
