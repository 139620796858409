import { NgModule, ModuleWithProviders, SkipSelf, Optional } from '@angular/core';
import { Configuration } from './configuration';
import { HttpClient } from '@angular/common/http';


import { AlertService } from './api/alert.service';
import { AlertModelService } from './api/alertModel.service';
import { ApiContainerService } from './api/apiContainer.service';
import { ApiCustomerService } from './api/apiCustomer.service';
import { ApiFileService } from './api/apiFile.service';
import { ApiMovementService } from './api/apiMovement.service';
import { ApiProductService } from './api/apiProduct.service';
import { ApiProductCategoryService } from './api/apiProductCategory.service';
import { ApiProfileService } from './api/apiProfile.service';
import { ApiQueueService } from './api/apiQueue.service';
import { ApiRoleService } from './api/apiRole.service';
import { ApiUserService } from './api/apiUser.service';
import { AreaService } from './api/area.service';
import { AssistanceRequestService } from './api/assistanceRequest.service';
import { BoxFormatService } from './api/boxFormat.service';
import { BrandService } from './api/brand.service';
import { ContainerService } from './api/container.service';
import { ContainerAllocationService } from './api/containerAllocation.service';
import { ContainerCompositionService } from './api/containerComposition.service';
import { ContainerModelService } from './api/containerModel.service';
import { ContainerStateService } from './api/containerState.service';
import { ControlProductService } from './api/controlProduct.service';
import { CustomerService } from './api/customer.service';
import { CustomerVisibilityCustomizationService } from './api/customerVisibilityCustomization.service';
import { DetailRestockingRequestService } from './api/detailRestockingRequest.service';
import { DistributorService } from './api/distributor.service';
import { ErrorService } from './api/error.service';
import { FileService } from './api/file.service';
import { LicenceService } from './api/licence.service';
import { LockService } from './api/lock.service';
import { LogApiService } from './api/logApi.service';
import { LogContainerContractService } from './api/logContainerContract.service';
import { LogUserService } from './api/logUser.service';
import { MovementService } from './api/movement.service';
import { PermissionService } from './api/permission.service';
import { ProductService } from './api/product.service';
import { ProductCategoriesService } from './api/productCategories.service';
import { ProfileService } from './api/profile.service';
import { RestockingRequestService } from './api/restockingRequest.service';
import { RoleService } from './api/role.service';
import { ServiceService } from './api/service.service';
import { SiteService } from './api/site.service';
import { TrackedProductService } from './api/trackedProduct.service';
import { UpdateManagerService } from './api/updateManager.service';
import { UserService } from './api/user.service';
import { UserAssignmentZoneService } from './api/userAssignmentZone.service';
import { UserContainerService } from './api/userContainer.service';

@NgModule({
  imports:      [],
  declarations: [],
  exports:      [],
  providers: [
    AlertService,
    AlertModelService,
    ApiContainerService,
    ApiCustomerService,
    ApiFileService,
    ApiMovementService,
    ApiProductService,
    ApiProductCategoryService,
    ApiProfileService,
    ApiQueueService,
    ApiRoleService,
    ApiUserService,
    AreaService,
    AssistanceRequestService,
    BoxFormatService,
    BrandService,
    ContainerService,
    ContainerAllocationService,
    ContainerCompositionService,
    ContainerModelService,
    ContainerStateService,
    ControlProductService,
    CustomerService,
    CustomerVisibilityCustomizationService,
    DetailRestockingRequestService,
    DistributorService,
    ErrorService,
    FileService,
    LicenceService,
    LockService,
    LogApiService,
    LogContainerContractService,
    LogUserService,
    MovementService,
    PermissionService,
    ProductService,
    ProductCategoriesService,
    ProfileService,
    RestockingRequestService,
    RoleService,
    ServiceService,
    SiteService,
    TrackedProductService,
    UpdateManagerService,
    UserService,
    UserAssignmentZoneService,
    UserContainerService ]
})
export class ApiModule {
    public static forRoot(configurationFactory: () => Configuration): ModuleWithProviders<ApiModule> {
        return {
            ngModule: ApiModule,
            providers: [ { provide: Configuration, useFactory: configurationFactory } ]
        };
    }

    constructor( @Optional() @SkipSelf() parentModule: ApiModule,
                 @Optional() http: HttpClient) {
        if (parentModule) {
            throw new Error('ApiModule is already loaded. Import in your base AppModule only.');
        }
        if (!http) {
            throw new Error('You need to import the HttpClientModule in your AppModule! \n' +
            'See also https://github.com/angular/angular/issues/20575');
        }
    }
}
