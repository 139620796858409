import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { UIResourcesProvider, UIText, UITextMap } from '@adista/window-kit-ui';

@Injectable()
export class TranslateKitService implements UIResourcesProvider {
  constructor(private readonly translateService: TranslateService) {}

  // Ensemble des chaines utilisées dans le kit.
  private readonly _textsFr: UITextMap = {
    [UIText.AgFilterContains]: 'Contient',
    [UIText.AgFilterEndsWith]: 'Finit par',
    [UIText.AgFilterEquals]: 'Égale',
    [UIText.AgFilterGreaterThanOrEquals]: 'Supérieur ou égal',
    [UIText.AgFilterGreaterThan]: 'Supérieur',
    [UIText.AgFilterInRangeFrom]: 'De...',
    [UIText.AgFilterInRangeTo]: 'À...',
    [UIText.AgFilterInRange]: 'Plage',
    [UIText.AgFilterLessThanOrEquals]: 'Inférieur ou égal',
    [UIText.AgFilterLessThan]: 'Inférieur',
    [UIText.AgFilterNotContains]: 'Ne contient pas',
    [UIText.AgFilterNotEqual]: 'Différent',
    [UIText.AgFilterStartsWith]: 'Commence par',
    [UIText.AgFilterValue]: 'Valeur',

    [UIText.DesktopAddTab]: 'Ajouter un bureau dans un nouveau navigateur',
    [UIText.DesktopAdd]: 'Ajouter un bureau',
    [UIText.DesktopDelete]: 'Supprimer',
    [UIText.DesktopTitle]: 'Mes bureaux',

    [UIText.EntityCreated]: 'Élément créé',
    [UIText.EntityDeleteError]: 'Impossible de supprimer cet élément',
    [UIText.EntityDeleted]: 'Élément supprimé',
    [UIText.EntityUpdated]: 'Élément mis à jour',

    [UIText.FormFileAdd]: 'Ajouter',

    [UIText.LockCancel]: 'Annuler',
    [UIText.LockForce]: 'Force',
    [UIText.LockForcedMessage]: "L'édition de cet élément a été forcée par {0}, vos modifications vont être annulées.",
    [UIText.LockLockedMessage]: 'Cet élément a été verrouillé le {0} par {1}',
    [UIText.LockOk]: 'OK',
    [UIText.LockTitle]: 'Verrouillage',

    [UIText.NavDisconnect]: 'Déconnexion',

    [UIText.NotifyError]: 'Erreur',
    [UIText.NotifyInfo]: 'Information',

    [UIText.WindowCancel]: 'Annuler',
    [UIText.WindowEdit]: 'Modifier',

    [UIText.WindowMenuConfig]: 'Configurer',
    [UIText.WindowMenuDelete]: 'Supprimer',
    [UIText.WindowMenuDuplicate]: 'Dupliquer',
    [UIText.WindowMenuPrint]: 'Imprimer',

    [UIText.WindowNew]: 'Nouveau',
    [UIText.WindowPanelsEmpty]: "Vous n'avez aucune fenêtre ouverte...",
    [UIText.WindowPanelsTitle]: 'Mes fenêtres ouvertes',
    [UIText.WindowSave]: 'Enregistrer',
  };

  private readonly _textsEn: UITextMap = {
    [UIText.AgFilterContains]: 'Contains',
    [UIText.AgFilterEndsWith]: 'Ends with',
    [UIText.AgFilterEquals]: 'Corresponds to',
    [UIText.AgFilterGreaterThanOrEquals]: 'Higher or equal',
    [UIText.AgFilterGreaterThan]: 'Superior',
    [UIText.AgFilterInRangeFrom]: 'From...',
    [UIText.AgFilterInRangeTo]: 'to...',
    [UIText.AgFilterInRange]: 'Beach',
    [UIText.AgFilterLessThanOrEquals]: 'Smaller or equal',
    [UIText.AgFilterLessThan]: 'Low',
    [UIText.AgFilterNotContains]: 'Does not contain',
    [UIText.AgFilterNotEqual]: 'Different',
    [UIText.AgFilterStartsWith]: 'Start with',
    [UIText.AgFilterValue]: 'Value',

    [UIText.DesktopAddTab]: 'Add a desktop in a new browser',
    [UIText.DesktopAdd]: 'Add desktop',
    [UIText.DesktopDelete]: 'Delete',
    [UIText.DesktopTitle]: 'My offices',

    [UIText.EntityCreated]: 'Created element',
    [UIText.EntityDeleteError]: 'This item cannot be deleted',
    [UIText.EntityDeleted]: 'Deleted element',
    [UIText.EntityUpdated]: 'Updated element',

    [UIText.FormFileAdd]: 'Add',

    [UIText.LockCancel]: 'Cancel',
    [UIText.LockForce]: 'Force',
    [UIText.LockForcedMessage]: 'Editing this element was forced by {0}, your changes will be undone.',
    [UIText.LockLockedMessage]: 'This element was locked on {0} by {1}.',
    [UIText.LockOk]: 'OK',
    [UIText.LockTitle]: 'Lock',

    [UIText.NavDisconnect]: 'Log out',

    [UIText.NotifyError]: 'Error',
    [UIText.NotifyInfo]: 'Information',

    [UIText.WindowCancel]: 'Cancel',
    [UIText.WindowEdit]: 'Edit',

    [UIText.WindowMenuConfig]: 'Set up',
    [UIText.WindowMenuDelete]: 'Delete',
    [UIText.WindowMenuDuplicate]: 'Duplicate',
    [UIText.WindowMenuPrint]: 'Print',

    [UIText.WindowNew]: 'New',
    [UIText.WindowPanelsEmpty]: 'They do not have open windows...',
    [UIText.WindowPanelsTitle]: 'My open windows',
    [UIText.WindowSave]: 'Register',
  };

  private readonly _textsNl: UITextMap = {
    [UIText.AgFilterContains]: 'Bevat',
    [UIText.AgFilterEndsWith]: 'Eindigt met',
    [UIText.AgFilterEquals]: 'Komt overeen met',
    [UIText.AgFilterGreaterThanOrEquals]: 'Hoger of gelijk',
    [UIText.AgFilterGreaterThan]: 'Superieur',
    [UIText.AgFilterInRangeFrom]: 'Van...',
    [UIText.AgFilterInRangeTo]: 'om...',
    [UIText.AgFilterInRange]: 'Strand',
    [UIText.AgFilterLessThanOrEquals]: 'Kleiner of gelijk',
    [UIText.AgFilterLessThan]: 'Laag',
    [UIText.AgFilterNotContains]: 'Bevat geen',
    [UIText.AgFilterNotEqual]: 'Anders',
    [UIText.AgFilterStartsWith]: 'Begin met',
    [UIText.AgFilterValue]: 'Waarde',

    [UIText.DesktopAddTab]: 'Voeg een bureaublad toe in een nieuwe browser',
    [UIText.DesktopAdd]: 'Desktop toevoegen',
    [UIText.DesktopDelete]: 'Delete',
    [UIText.DesktopTitle]: 'Mijn kantoren',

    [UIText.EntityCreated]: 'Gecreëerd element',
    [UIText.EntityDeleteError]: 'Dit item kan niet worden verwijderd',
    [UIText.EntityDeleted]: 'Verwijderde element',
    [UIText.EntityUpdated]: 'Bijgewerkt element',

    [UIText.FormFileAdd]: 'Toevoegen',

    [UIText.LockCancel]: 'Annuleren',
    [UIText.LockForce]: 'Vermogen',
    [UIText.LockForcedMessage]: 'Het wijzigen van dit item werd geforceerd door {0}, uw wijzigingen zullen ongedaan worden gemaakt.',
    [UIText.LockLockedMessage]: 'Dit item was vergrendeld op {0} door {1}.',
    [UIText.LockOk]: 'OK',
    [UIText.LockTitle]: 'Slot',

    [UIText.NavDisconnect]: 'Log uit.',

    [UIText.NotifyError]: 'Fout',
    [UIText.NotifyInfo]: 'Informatie',

    [UIText.WindowCancel]: 'Annuleren',
    [UIText.WindowEdit]: 'Bewerken',

    [UIText.WindowMenuConfig]: 'Opzetten',
    [UIText.WindowMenuDelete]: 'Delete',
    [UIText.WindowMenuDuplicate]: 'Duplicaat',
    [UIText.WindowMenuPrint]: 'Afdrukken',

    [UIText.WindowNew]: 'Nieuw',
    [UIText.WindowPanelsEmpty]: 'Ze hebben geen open ramen...',
    [UIText.WindowPanelsTitle]: 'Mijn open ramen',
    [UIText.WindowSave]: 'Registreren',
  };

  private readonly _textsDe: UITextMap = {
    [UIText.AgFilterContains]: 'Enthält',
    [UIText.AgFilterEndsWith]: 'Beendet mit',
    [UIText.AgFilterEquals]: 'Entspricht',
    [UIText.AgFilterGreaterThanOrEquals]: 'Höher oder gleich',
    [UIText.AgFilterGreaterThan]: 'Hochwertig',
    [UIText.AgFilterInRangeFrom]: 'Von...',
    [UIText.AgFilterInRangeTo]: 'zu ...',
    [UIText.AgFilterInRange]: 'Strand',
    [UIText.AgFilterLessThanOrEquals]: 'Kleiner oder gleich',
    [UIText.AgFilterLessThan]: 'Niedriger',
    [UIText.AgFilterNotContains]: 'Enthält nicht',
    [UIText.AgFilterNotEqual]: 'Anders',
    [UIText.AgFilterStartsWith]: 'Beginne mit',
    [UIText.AgFilterValue]: 'Wert',

    [UIText.DesktopAddTab]: 'Einen Desktop in einem neuen Browser hinzufügen',
    [UIText.DesktopAdd]: 'Desktop hinzufügen',
    [UIText.DesktopDelete]: 'Löschen',
    [UIText.DesktopTitle]: 'Meine Büros',

    [UIText.EntityCreated]: 'Erstelltes Element',
    [UIText.EntityDeleteError]: 'Dieses Element kann nicht gelöscht werden',
    [UIText.EntityDeleted]: 'Gelöschtes Element',
    [UIText.EntityUpdated]: 'Aktualisiertes Element',

    [UIText.FormFileAdd]: 'Hinzufügen',

    [UIText.LockCancel]: 'Abbrechen',
    [UIText.LockForce]: 'Kraft',
    [UIText.LockForcedMessage]: 'Die Bearbeitung dieses Elements wurde durch {0} erzwungen, Ihre Änderungen werden rückgängig gemacht.',
    [UIText.LockLockedMessage]: 'Dieses Element wurde am {0} von {1} gesperrt.',
    [UIText.LockOk]: 'OK',
    [UIText.LockTitle]: 'Sperren',

    [UIText.NavDisconnect]: 'Abmelden',

    [UIText.NotifyError]: 'Fehler',
    [UIText.NotifyInfo]: 'Informationen',

    [UIText.WindowCancel]: 'Abbrechen',
    [UIText.WindowEdit]: 'Bearbeiten',

    [UIText.WindowMenuConfig]: 'Einrichten',
    [UIText.WindowMenuDelete]: 'Löschen',
    [UIText.WindowMenuDuplicate]: 'Duplizieren',
    [UIText.WindowMenuPrint]: 'Drucken',

    [UIText.WindowNew]: 'Neue',
    [UIText.WindowPanelsEmpty]: 'Sie haben keine offenen Fenster...',
    [UIText.WindowPanelsTitle]: 'Meine offenen Fenster',
    [UIText.WindowSave]: 'Registrieren',
  };

  private readonly _textsIt: UITextMap = {
    [UIText.AgFilterContains]: 'Contiene',
    [UIText.AgFilterEndsWith]: 'Finisce con',
    [UIText.AgFilterEquals]: 'Corrisponde a',
    [UIText.AgFilterGreaterThanOrEquals]: 'Superiore o uguale',
    [UIText.AgFilterGreaterThan]: 'Superiore',
    [UIText.AgFilterInRangeFrom]: 'Da...',
    [UIText.AgFilterInRangeTo]: 'a...',
    [UIText.AgFilterInRange]: 'Spiaggia',
    [UIText.AgFilterLessThanOrEquals]: 'Più piccolo o uguale',
    [UIText.AgFilterLessThan]: 'Basso',
    [UIText.AgFilterNotContains]: 'Non contiene',
    [UIText.AgFilterNotEqual]: 'Diverso',
    [UIText.AgFilterStartsWith]: 'Iniziare con',
    [UIText.AgFilterValue]: 'Valore',

    [UIText.DesktopAddTab]: 'Aggiungere un desktop in un nuovo browser',
    [UIText.DesktopAdd]: 'Aggiungi il desktop',
    [UIText.DesktopDelete]: 'Cancellare',
    [UIText.DesktopTitle]: 'I miei uffici',

    [UIText.EntityCreated]: 'Elemento creato',
    [UIText.EntityDeleteError]: 'Questa voce non può essere cancellata',
    [UIText.EntityDeleted]: 'Elemento cancellato',
    [UIText.EntityUpdated]: 'Elemento aggiornato',

    [UIText.FormFileAdd]: 'Aggiungi',

    [UIText.LockCancel]: 'Cancella',
    [UIText.LockForce]: 'Potenza',
    [UIText.LockForcedMessage]: 'La modifica di questo elemento è stata forzata da {0}, le tue modifiche saranno annullate.',
    [UIText.LockLockedMessage]: 'Questo oggetto è stato bloccato su {0} da {1}.',
    [UIText.LockOk]: 'OK',
    [UIText.LockTitle]: 'Bloccare',

    [UIText.NavDisconnect]: 'Disconnettersi',

    [UIText.NotifyError]: 'Errore',
    [UIText.NotifyInfo]: 'Informazioni',

    [UIText.WindowCancel]: 'Cancella',
    [UIText.WindowEdit]: 'Modifica',

    [UIText.WindowMenuConfig]: 'Impostazione',
    [UIText.WindowMenuDelete]: 'Cancellare',
    [UIText.WindowMenuDuplicate]: 'Duplicato',
    [UIText.WindowMenuPrint]: 'Stampa',

    [UIText.WindowNew]: 'Nuovo',
    [UIText.WindowPanelsEmpty]: 'Non hanno finestre aperte...',
    [UIText.WindowPanelsTitle]: 'Le mie finestre aperte',
    [UIText.WindowSave]: 'Registra',
  };

  private readonly _textsEs: UITextMap = {
    [UIText.AgFilterContains]: 'Contiene',
    [UIText.AgFilterEndsWith]: 'Termina con',
    [UIText.AgFilterEquals]: 'Igual',
    [UIText.AgFilterGreaterThanOrEquals]: 'Mayor o igual',
    [UIText.AgFilterGreaterThan]: 'Superior',
    [UIText.AgFilterInRangeFrom]: 'De...',
    [UIText.AgFilterInRangeTo]: 'a ...',
    [UIText.AgFilterInRange]: 'Playa',
    [UIText.AgFilterLessThanOrEquals]: 'Más pequeño o igual',
    [UIText.AgFilterLessThan]: 'Bajo',
    [UIText.AgFilterNotContains]: 'No contiene',
    [UIText.AgFilterNotEqual]: 'Diferente',
    [UIText.AgFilterStartsWith]: 'Comience con',
    [UIText.AgFilterValue]: 'Valor',

    [UIText.DesktopAddTab]: 'Añadir un escritorio en un nuevo navegador',
    [UIText.DesktopAdd]: 'Añadir escritorio',
    [UIText.DesktopDelete]: 'Borrar',
    [UIText.DesktopTitle]: 'Mis oficinas',

    [UIText.EntityCreated]: 'Elemento creado',
    [UIText.EntityDeleteError]: 'Este elemento no puede ser eliminado',
    [UIText.EntityDeleted]: 'Elemento eliminado',
    [UIText.EntityUpdated]: 'Elemento actualizado',

    [UIText.FormFileAdd]: 'Añadir',

    [UIText.LockCancel]: 'Cancelar',
    [UIText.LockForce]: 'Potencia',
    [UIText.LockForcedMessage]: 'La edición de este elemento fue forzada por {0}, sus cambios serán deshechos.',
    [UIText.LockLockedMessage]: 'Este artículo fue bloqueado en {0} por {1}.',
    [UIText.LockOk]: 'OK',
    [UIText.LockTitle]: 'Cerradura',

    [UIText.NavDisconnect]: 'Cerrar la sesión',

    [UIText.NotifyError]: 'Error',
    [UIText.NotifyInfo]: 'Información',

    [UIText.WindowCancel]: 'Cancelar',
    [UIText.WindowEdit]: 'Editar',

    [UIText.WindowMenuConfig]: 'Preparar',
    [UIText.WindowMenuDelete]: 'Borrar',
    [UIText.WindowMenuDuplicate]: 'Duplicado',
    [UIText.WindowMenuPrint]: 'Imprimir',

    [UIText.WindowNew]: 'Nuevo',
    [UIText.WindowPanelsEmpty]: 'No tienen ventanas abiertas...',
    [UIText.WindowPanelsTitle]: 'Mis ventanas abiertas',
    [UIText.WindowSave]: 'Registro',
  };

  private readonly _textsPt: UITextMap = {
    [UIText.AgFilterContains]: 'Contém',
    [UIText.AgFilterEndsWith]: 'Termina com',
    [UIText.AgFilterEquals]: 'Corresponde a',
    [UIText.AgFilterGreaterThanOrEquals]: 'Superior ou igual',
    [UIText.AgFilterGreaterThan]: 'Superior',
    [UIText.AgFilterInRangeFrom]: 'De...',
    [UIText.AgFilterInRangeTo]: 'para ...',
    [UIText.AgFilterInRange]: 'Praia',
    [UIText.AgFilterLessThanOrEquals]: 'Mais pequeno ou igual',
    [UIText.AgFilterLessThan]: 'Baixo',
    [UIText.AgFilterNotContains]: 'Não contém',
    [UIText.AgFilterNotEqual]: 'Diferente',
    [UIText.AgFilterStartsWith]: 'Comece com',
    [UIText.AgFilterValue]: 'Valor',

    [UIText.DesktopAddTab]: 'Adicionar uma área de trabalho em um novo navegador',
    [UIText.DesktopAdd]: 'Adicionar área de trabalho',
    [UIText.DesktopDelete]: 'Eliminar',
    [UIText.DesktopTitle]: 'Meus escritórios',

    [UIText.EntityCreated]: 'Elemento criado',
    [UIText.EntityDeleteError]: 'Este item não pode ser eliminado',
    [UIText.EntityDeleted]: 'Elemento eliminado',
    [UIText.EntityUpdated]: 'Elemento atualizado',

    [UIText.FormFileAdd]: 'Adicione',

    [UIText.LockCancel]: 'Cancelar',
    [UIText.LockForce]: 'Energia',
    [UIText.LockForcedMessage]: 'A edição deste item foi forçada por {0}, as suas alterações serão desfeitas.',
    [UIText.LockLockedMessage]: 'Este item foi bloqueado em {0} por {1}.',
    [UIText.LockOk]: 'OK',
    [UIText.LockTitle]: 'Fechadura',

    [UIText.NavDisconnect]: 'Sair',

    [UIText.NotifyError]: 'Erro',
    [UIText.NotifyInfo]: 'Informações',

    [UIText.WindowCancel]: 'Cancelar',
    [UIText.WindowEdit]: 'Editar',

    [UIText.WindowMenuConfig]: 'Instalar',
    [UIText.WindowMenuDelete]: 'Eliminar',
    [UIText.WindowMenuDuplicate]: 'Duplicata',
    [UIText.WindowMenuPrint]: 'Imprimir',

    [UIText.WindowNew]: 'Novo',
    [UIText.WindowPanelsEmpty]: 'Eles não têm janelas abertas...',
    [UIText.WindowPanelsTitle]: 'As minhas janelas abertas',
    [UIText.WindowSave]: 'Registe-se',
  };

  public get texts(): UITextMap {
    switch (this.translateService.currentLang) {
      case 'fr': {
        return this._textsFr;
      }
      case 'en': {
        return this._textsEn;
      }
      case 'nl': {
        return this._textsNl;
      }
      case 'de': {
        return this._textsDe;
      }
      case 'it': {
        return this._textsIt;
      }
      case 'es': {
        return this._textsEs;
      }
      case 'pt': {
        return this._textsPt;
      }
      default: {
        return this._textsFr;
      }
    }
  }

  public translateProp(holder, key, prop, suffix = ''): void {
    this.translateService.get(key).subscribe((res: string) => {
      let result = '';
      if (res.includes('{{suffix}}')) {
        result = res.replace('{{suffix}}', suffix);
      } else {
        result = res;
      }
      holder[prop] = result;
    });
  }

  public translatePropWithParam(holder, key, param, prop): void {
    this.translateService.get(key, param).subscribe((res: string) => {
      holder[prop] = res;
    });
  }

  public translateInstant(key): any {
    return this.translateService.instant(key);
  }
}
