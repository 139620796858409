import { CustomPermissionName, PermissionName } from '@shared/models/const';

export class PermissionAccess {
  public readonly create: string;
  public readonly read: string;
  public readonly edit: string;
  public readonly delete: string;
  public readonly menu: string;
  public readonly admin: string;
  public readonly custom: string;

  constructor(public readonly name: string) {
    this.create = `${this.name}:create`;
    this.read = `${this.name}:read`;
    this.edit = `${this.name}:edit`;
    this.delete = `${this.name}:delete`;
    this.menu = `${this.name}:menu`;
    this.admin = `${this.name}:admin`;
    this.custom = `${this.name}`;
  }
}

export class PermissionDefiner {
  public static readonly ACCOUNT = new PermissionAccess(CustomPermissionName.MyAccountMenu);
  public static readonly AREA = new PermissionAccess(PermissionName.Area);
  public static readonly MYAREAMENU = new PermissionAccess(CustomPermissionName.MyAreaMenu);
  public static readonly COMPANY = new PermissionAccess(CustomPermissionName.MyCompanyMenu);
  public static readonly CONTAINER = new PermissionAccess(PermissionName.Container);
  public static readonly CUSTOMER = new PermissionAccess(PermissionName.Customer);
  public static readonly MOVEMENT = new PermissionAccess(PermissionName.Movement);
  public static readonly MYROLE = new PermissionAccess(CustomPermissionName.MyRoleMenu);
  public static readonly PRODUCT = new PermissionAccess(PermissionName.Product);
  public static readonly SERVICE = new PermissionAccess(PermissionName.Service);
  public static readonly MYSERVICEMENU = new PermissionAccess(CustomPermissionName.MyServiceMenu);
  public static readonly SITE = new PermissionAccess(PermissionName.Site);
  public static readonly MYSITEMENU = new PermissionAccess(CustomPermissionName.MySiteMenu);
  public static readonly ROLE = new PermissionAccess(PermissionName.Role);
  public static readonly USER = new PermissionAccess(PermissionName.User);
  public static readonly BRAND = new PermissionAccess(PermissionName.Brand);
  public static readonly DISTRIBUTOR = new PermissionAccess(PermissionName.Distributor);
  public static readonly PRODUCTCATEGORIES = new PermissionAccess(PermissionName.ProductCategories);
  public static readonly TRACKEDPRODUCT = new PermissionAccess(PermissionName.TrackedProduct);
  public static readonly CONTROLPRODUCT = new PermissionAccess(PermissionName.ControlProduct);
  public static readonly CONTAINERMODEL = new PermissionAccess(PermissionName.ContainerModel);
  public static readonly BOXFORMAT = new PermissionAccess(PermissionName.BoxFormat);
  public static readonly PROFILE = new PermissionAccess(PermissionName.Profile);
  public static readonly ERROR = new PermissionAccess(PermissionName.Error);
  public static readonly CONTAINERINPENDING = new PermissionAccess(PermissionName.ContainerInPending);
  public static readonly ASSISTANCEREQUEST = new PermissionAccess(PermissionName.AssistanceRequest);
  public static readonly LOGCONTAINERCONTRACT = new PermissionAccess(PermissionName.LogContainerContract);
  public static readonly UPDATEMANAGER = new PermissionAccess(PermissionName.UpdateManager);
  public static readonly ALERTMODEL = new PermissionAccess(PermissionName.AlertModel);
  public static readonly ALERT = new PermissionAccess(PermissionName.Alert);
  public static readonly RESTOCKINGREQUEST = new PermissionAccess(PermissionName.RestockingRequest);
  public static readonly DASHBOARD = new PermissionAccess(PermissionName.Dashboard);
}
