import { Component, ElementRef, OnInit, Renderer2, ViewChild } from '@angular/core';
import { MenuService } from '@shared/services/menu.service';
import { ApiUser, hasUserPermission, KitService, WindowItem } from '@adista/window-kit-ui';
import { CurrentUserService } from '@shared/services/currentuser.service';
import { CustomMenuItem } from '@shared/models/custom-menu-item';
import { User } from '@api/model/user';
import { CustomerVisibilityCustomizationService } from '@api/api/customerVisibilityCustomization.service';
import { CustomerVisibilityCustomization } from '@api/model/customerVisibilityCustomization';

@Component({
  selector: 'app-boardnav',
  templateUrl: './boardnav.component.html',
  styleUrls: ['./boardnav.component.scss'],
})
export class BoardnavComponent implements OnInit {
  activeMobileMenu = false;
  closeTips = false;
  currentCat = null;
  menus: CustomMenuItem[];
  duplicatedWindow: WindowItem;
  public urlLogo = '/assets/img/samtools-supervisor-logo.svg';
  @ViewChild('toggleButton') toggleButton: ElementRef;

  constructor(
    private readonly menuService: MenuService,
    private readonly currentUserService: CurrentUserService,
    private readonly customerVisibilityCustomizationService: CustomerVisibilityCustomizationService,
    private readonly kit: KitService,
    private renderer: Renderer2,
  ) {
    this.renderer.listen('window', 'click', (e: Event) => {
      if (!this.toggleButton.nativeElement.contains(e.target)) {
        this.currentCat = null;
      }
    });
  }

  ngOnInit() {
    this.currentUserService.currentUser$.subscribe((user: User) => {
      this.menuService.getMenus().subscribe((menu: CustomMenuItem[]) => {
        this.menus = this.filterMenus(user, menu);
      });
      if (user && user.referringCustomer) {
        this.customerVisibilityCustomizationService
          .getCustomerVisibilityCustomizationByCustomerId(user.referringCustomer)
          .subscribe((customerVisibilityCustomization: CustomerVisibilityCustomization) => {
            if (customerVisibilityCustomization.logoUrl) {
              this.urlLogo = customerVisibilityCustomization.logoUrl;
            }
          });
      }
    });
  }

  private filterMenus(user: ApiUser, data: CustomMenuItem[]): CustomMenuItem[] {
    const filteredMenu: CustomMenuItem[] = [];

    data.forEach((menu: CustomMenuItem) => {
      if (!menu.permission || hasUserPermission(user, menu.permission)) {
        const newMenu: CustomMenuItem = { ...menu };

        if (menu.subItems && menu.subItems.length > 0) {
          newMenu.subItems = this.filterMenus(user, menu.subItems);
          if (newMenu.subItems && newMenu.subItems.length > 0) {
            filteredMenu.push(newMenu);
          }
        } else {
          filteredMenu.push(newMenu);
        }
      }
    });

    return filteredMenu;
  }

  menuItemClicked(item: CustomMenuItem, index: number) {
    this.duplicatedWindow = undefined;

    if (index === 0) {
      index = null;
    }

    if (this.currentCat === index) {
      this.currentCat = 0;
    } else {
      this.currentCat = index;
    }

    // Recherche des fenêtres dupliquées
    this.kit.desktop.windows$.subscribe((windowList: WindowItem[]) => {
      for (const window of windowList) {
        if (window.title === item.label) {
          this.duplicatedWindow = window;
          break;
        }
      }
    });

    // Bloque l'ouverture de la fenêtre si elle est déjà ouverte + focus
    if (this.duplicatedWindow) {
      this.kit.desktop.setWindowVisibility(this.duplicatedWindow, true);
      return;
    }

    if (item) {
      if (item.link) {
        this.kit.routing.openLink(item.link);
        // Ferme le menu en mode mobile
        // this.activeMobileMenu = false;
      } else {
        // Si un seul sous-menu, l'entrée de menu ouvre directement la page
        if (item.subItems.length === 1) {
          this.menuItemClicked(item.subItems[0], 0);
        }
      }
    }
  }
}
